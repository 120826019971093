import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavigationBar from "../common/navigation/navigationbar";
import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import Footer from "../common/footer/footer";
import NotFound from "../404notfound/404notfound";
import ApplicationLandingPage from "../landingpage/applicationlandingpage";
import ComingSoon from "../common/coming-soon";
import ApplicantLogin from "../loginpage/login";
import ApplicationRegistration from "../registrationpage/registrationpage";
import ApplicationDashboard from "../dashboard/applicationdashboard";
import GuardianInformation from "../undergraduate/GuardianInformation";
import ApplicationRecoverPassword from "../loginpage/recover-password";
import DocumentUpload from "../undergraduate/DocumentUpload";
import PersonalInformation from "../undergraduate/personal-information";
import UGApplicationPreview from "../undergraduate/document-preview";
import ChangePassword from "../loginpage/recover-passwords";
import UndergraduateApplicationDashboard from "../undergraduate/dashboard";
import Olevel from "../undergraduate/Olevel";
import NewStudentEnrolment from "../new-student-enrolment/new-student-enrolment";
import Jamb from "../undergraduate/jamb";
import Selection from "../undergraduate/selection"

export default function PageRoutes() {
  return (
    <Routers>
      <NavigationBar />

      <Routes>
        <Route path={"/"} element={<ApplicationLandingPage />} />
        <Route path={"/apply-now"} element={<ApplicationLandingPage/>} />
        <Route path="/coming-soon" element={<ComingSoon/>} />
        {/*Application End*/}
        <Route path={"*"} element={<NotFound />} />
        <Route path="/admission/application/login" element={<ApplicantLogin/>}  />
        <Route path="/admission/application/register" element={<ApplicationRegistration/>}  />
        <Route path="/admission/application/dashboard" element={<ApplicationDashboard/>}  />
        <Route path="/admission/application/undergraduate/guardian-information" element={<GuardianInformation/>} />
        <Route path="/admission/application" element={<ApplicantLogin/>}  />
        <Route path="/admission/application/recover-password" element={<ApplicationRecoverPassword/>} />
        <Route path="/admission/application/recover-password/:slug" element={<ChangePassword/>} />
        <Route path="/admission/application/undergraduate/guardian-information" element={<GuardianInformation/>} />
        <Route path="/admission/application/undergraduate/documents-uploads" element={<DocumentUpload/>} />
        <Route path="/admission/application/undergraduate/document-preview" element={<UGApplicationPreview/>} />
        <Route path="/admission/application/undergraduate/personal-information" element={<PersonalInformation/>} />
        <Route path="/admission/application/undergraduate" element={<UndergraduateApplicationDashboard/>} />
        <Route path="/admission/application/undergraduate/selection" element={<Selection />} />
        <Route path="/admission/application/undergraduate/secondary-school-result" element={<Olevel/>} />
        <Route path="/admission/application/undergraduate/jamb-result" element={<Jamb/>} />
        <Route path="/admission/application/enrolment" element={<NewStudentEnrolment/>} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />

      <Footer />
    </Routers>
  );
}
